<template>
    <div>
        <div class="bg">
            <span class="text">欢迎使用{{name}}系统</span>
            <img class="bg-img" src="../../assets/home/bg.png" alt="" draggable="false">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {                
                name: '货运管理',
            }
        },
    }

</script>

<style lang="scss" scoped>
.bg{
    position: relative; 
    height: 100%;
}
.bg-img {
    width: 1400px;    
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;

}
.text{
    width: 464px;
    height: 60px;
    font-size: 45px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3995FE;
    line-height: 136px;
    position: absolute;
    left: 100px;
    top: 50px;
    z-index: 9999;
    user-select: none;
}
</style>